import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/backbone-routers",
  "date": "2014-12-08",
  "title": "BACKBONE- ROUTERS",
  "author": "admin",
  "tags": ["development", "javascript", "backbone.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "In the traditional MVC sense Routers don’t necessarily fit the semantics. Though a Backbone \"router\" is still very useful for any application/feature that needs URL routing/history capabilities. Backbone routers are used for routing your applications URL’s when using hash tags(#)."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Defined routers should always contain at least one route and a function to map the particular route to. In the example below, we are going to define a route that is always called.`}</p>
    <h2>{`Optional Routes`}</h2>
    <p>{`Search route with query and optional page parameter is an optional route.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var TodoRouter = new (Backbone.Router.extend({
    routes: {
        'search/:query': 'search',
        'search/:query/p:page': 'search'
    },
    search: function(query, page) {
        page = page || 0;
        console.log(query);
        console.log(page);
    }
}));
`}</code></pre>
    <p>{`Results:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`TodoRouter.navigate('search/milk', {trigger: true});
`}</code></pre>
    <p>{`This will print `}<inlineCode parentName="p">{`"milk", 0`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`TodoRouter.navigate('search/milk/p2', {trigger: true});
`}</code></pre>
    <p>{`This will print `}<inlineCode parentName="p">{`"milk", 2`}</inlineCode></p>
    <p>{`Note the duplicate routes are highlighted. We can resolve this issue with an optional route:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var TodoRouter = new (Backbone.Router.extend({
    routes: {
        'search/:query /p:page ': 'search',
    },
    search: function(query, page) {
        page = page || 0;
        console.log(query);
        console.log(page);
    }
}));
`}</code></pre>
    <p>{`What if we want to match ‘search/milk/p2/’?`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var TodoRouter = new (Backbone.Router.extend({
    routes: {
        'search/:query (/p:page)(/) ': 'search',
    },
    search: function(query, page) {
        page = page || 0;
        console.log(query);
        console.log(page);
    }
}));
`}</code></pre>
    <p>{`We can use an optional trailing slash `}<inlineCode parentName="p">{`(/)`}</inlineCode></p>
    <h2>{`URI with Spaces`}</h2>
    <p>{`What if the URI you provide has encoded spaces?`}</p>
    <p><inlineCode parentName="p">{`To doRouter.navigate('search/Hello%20World/p2', {trigger: true})`}</inlineCode></p>
    <p>{`You will get the following result, which is not decoded:`}</p>
    <p><inlineCode parentName="p">{`"Hello%20World", 2`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var TodoRouter = new (Backbone.Router.extend({
    routes: {
        'search/:query (/p:page)(/) ': 'search',
    },
    search: function(query, page) {
        page = page || 0;
        query = decodeURIComponent(query);
        console.log(query);
        console.log(page);
    }
}));
`}</code></pre>
    <p>{`The result will be decoded:`}</p>
    <p><inlineCode parentName="p">{`"Hello World", 2`}</inlineCode></p>
    <h2>{`Regex in Routes`}</h2>
    <p>{`Say we want to restrict parameter to numeric input, let's see how can achieve this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var TodoRouter = new (Backbone.Router.extend({
    routes: {
        'todos/:id': 'show'
    },
    show: function(id) {
        console.log("id = " + id);
    }
}));
`}</code></pre>
    <p><inlineCode parentName="p">{`TodoRouter.navigate('todos/2', {trigger: true})`}</inlineCode></p>
    <p>{`Result: `}<inlineCode parentName="p">{`id = 2`}</inlineCode></p>
    <p><inlineCode parentName="p">{`TodoRouter.navigate('todos/notanid', {trigger: true})`}</inlineCode></p>
    <p>{`Result `}<inlineCode parentName="p">{`id = notanid`}</inlineCode></p>
    <p>{`So this shouldn’t trigger show.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var TodoRouter = new (Backbone.Router.extend({
    show: function(id) {
    console.log("id = " + id);
}
}));    

TodoRouter.route(/^todos\\/(\\d+)$/, 'show');
`}</code></pre>
    <p>{`Here each Regex Capture Group becomes a param.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`TodoRouter.navigate('todos/2', {trigger: true}) // id = 2\`
TodoRouter.navigate('todos/notanid', {trigger: true})
`}</code></pre>
    <p>{`This doesn't trigger show.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var TodoRouter = new (Backbone.Router.extend({
    initialize: function(){
        this.route(/^todos\\/(\\d+)$/, 'show');
    },
    show: function(id) {
    console.log("id = " + id);
}
}));    
`}</code></pre>
    <p>{`This keeps routes inside the router.`}</p>
    <h2>{`Advanced Regex Routes`}</h2>
    <p>{`Let's examine this advanced route formation:`}</p>
    <p><inlineCode parentName="p">{`/^search\\/(\\w+)(?:\\/p(\\d+))?\\/?$/ --> 'search/:query(/p:page)'`}</inlineCode></p>
    <p><img parentName="p" {...{
        "src": "https://farm8.staticflickr.com/7575/15354908123_a0f3f95a10_o.png",
        "alt": "Advanced Regex Routes"
      }}></img></p>
    <p>{`This router matches:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`'search/milk'
'search/milk/p2'
'search/milk/p2/'
`}</code></pre>
    <p>{`But it doesn't match the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`'searches/milk'
'search/milk/p2/p1'
'search/milk/2'
`}</code></pre>
    <h2>{`Catch-all Routes`}</h2>
    <p>{`Alert user when no route matches:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var TodoRouter = Backbone.Router.extend({
    routes: {
        '*path': 'notFound'
    },
        notFound: function(path) {
        alert('Sorry! There is no content here.');
    }
});

TodoRouter.navigate('nothinghere', {trigger: true})
`}</code></pre>
    <p>{`This will result in:`}</p>
    <p><img parentName="p" {...{
        "src": "https://farm9.staticflickr.com/8648/15352305684_b397fc7704_o.png",
        "alt": "Catch-all Routes"
      }}></img></p>
    <h2>{`File Path Route`}</h2>
    <p>{`Accept a file path and get its parts`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var TodoRouter = new (Backbone.Router.extend({
    routes: {
        'file/*path': 'file'
    },
    file: function(path) {
        var parts = path.split("/");
        console.log(parts);
    }
}));

TodoRouter.navigate("file/this/is/a/file.txt", {trigger: true});
`}</code></pre>
    <p>{`Result:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`[" this", "is", "a", "file.txt"]
`}</code></pre>
    <p>{`If you want to learn about more follow my other articles on Backbone here:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/developer/backbone-models/"
        }}>{`Backbone Models`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/developer/backbone-customizing-collections/"
        }}>{`Backbone Customizing Collections`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/developer/backbone-patterns/"
        }}>{`Backbone Patterns`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/developer/requirejs-handlebars/"
        }}>{`RequireJS & Handlebars`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      